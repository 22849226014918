<template>
  <section   v-if="this.$localStorage.get(this.$store.storeName) != null">
    <div
         v-if="loading"
        class="d-flex flex-row justify-content-center"
        style="margin-top: 20%;  ;margin-left:45%"
      >
        <!-- LOADER -->
        <div >
  <hollow-dots-spinner
          :animation-duration="1000"
          :dot-size="35"
          :dots-num="3"
          color="var(--primary-color)"
        />
     
</div>
      </div>
  <!-- Start Hero -->
  <section v-else style="position:absloute"
  class="relative lg:pb-24 pb-16 md:mt-[84px] mt-[70px]">
             <div class="md:container container-fluid relative">
                 <div class="relative overflow-hidden md:rounded-md shadow
                  dark:shadow-gray-700 h-52 
                  bg-[url('https://marketetrolly.s3.eu-north-1.amazonaws.com/media/217/section/2024/03/327518311709552687.jpg')] 
                  bg-center bg-no-repeat bg-cover" 
                  style="background-image:url('https://marketetrolly.s3.eu-north-1.amazonaws.com/media/227/slider/2024/08/1723628771_1723717856.jpg')"
                  ></div>
             </div><!--end container-->
 
             <div class="container relative md:mt-24 mt-16">
                 <div class="md:flex">
                     <side_bar></side_bar>
 
                     <div class="lg:w-3/4 md:w-2/3 md:px-3 mt-6 md:mt-0">
                        <h5 class="text-lg font-semibold mb-6">{{ $t('myOrders') }}</h5>
                        <div class="relative overflow-x-auto shadow dark:shadow-gray-800 rounded-md">
                            <table v-if="this.DBitems.length!=0" class="w-full text-start text-slate-500 dark:text-slate-400">
                                <thead class="text-sm uppercase bg-slate-50 dark:bg-slate-800">
                                    <tr class="text-start">
                                        <th scope="col" class="px-2 py-3 text-start">{{ $t('Order no.') }}</th>
                                        <th scope="col" class="px-2 py-3 text-start">{{ $t('Date') }}</th>
                                        <th scope="col" class="px-2 py-3 text-start">{{ $t('status') }}</th>
                                        <th scope="col" class="px-2 py-3 text-start">{{ $t('total') }}</th>
                                        <th scope="col" class="px-2 py-3 text-start">{{ $t('Action') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr 
                                      v-for="(item, index) in this.DBitems"
                                            :key="index"
                                    class="bg-white dark:bg-slate-900 text-start">
                                        <th class="px-2 py-3 text-start" scope="row">#{{ item.invoice_id }}</th>
                                        <td class="px-2 py-3 text-start">{{ item.date }}</td>
                                        <td class="px-2 py-3 text-start text-green-600">{{ item.state }}</td>
                                        <td class="px-2 py-3 text-start">{{ item.total_price }} {{ unit}} 
                                          </td>
                                        <td class="px-2 py-3 text-start">
                                          <a href="javascript:void(0)" class="">
                                            <router-link
                                            :to="{
                                            name: 'invoice',
                                            params: { storeName: $store.storeName 
                                            ,id:item.id,
                                            lang: $i18n.locale
                                             }
                                            }"
                                            >
                                            {{ $t('view') }} 
                                            <i class="mdi mdi-chevron-right"></i>
                                            </router-link>
                                            </a></td>
                                    </tr>

                                    
                                </tbody>
                            </table>

                              
                     
        
            <div v-else>
                  <div class="empty text-center center-text">
                 
                  <img src="/assets/images/empty.png"/>
                  <br>
                  {{ $t('empty') }}
                  </div>
                  </div>
                  <div  class="grid md:grid-cols-12 grid-cols-1 mt-6">
                    <div class="md:col-span-12 text-center">
                        <nav aria-label="Page navigation example">
                            <b-pagination
                v-if="this.DBitems.length>1"
                v-model="page"
                :total-rows="count"
                :per-page="this.paginate"
                :prev-text="$t('prev')"
                :next-text="$t('next')"
                pills
                size="sm"
                class="paginate_theme8"
                @change="handlePageChange"
              ></b-pagination>
                        </nav>
                    </div><!--end col-->
                </div><!--end grid-->

                            
                        </div>

                    </div>
                 </div><!--end grid-->
             </div><!--end container-->

             
         </section><!--end section-->

         </section>
         <!-- End Hero -->
 </template>

 <script>
import order_list from "../components/order_list.vue";
import side_bar from "../components/sidebar_profile.vue";
import { HalfCircleSpinner, HollowDotsSpinner,CirclesToRhombusesSpinner } from "epic-spinners";

export default {
    components: { order_list,side_bar,
      HalfCircleSpinner, 
      HollowDotsSpinner,
      CirclesToRhombusesSpinner
    },
  data: function() {
    return {
      DBitems: null,
      DBitems1: null,

      loading: false,
      loading1: false,

      activeTab: false,
      page: 1,
      count: 0,
      pageSize: 6,

      page1: 1,
      count1: 0,
      pageSize1: 6,

      langChange:"",
      unit: this.$store.state.unit,
    };
  },
  created() {
    this.$store.state.topbar='topbar_dark';
    this.$store.state.navbar='navbar_dark navbar_top';
    
    this.unit=this.$store.state.unit;
    if(this.$i18n.locale == 'ar')
    {
        this.unit=this.$store.state.unitAR;
    }
    else
      {
        this.unit=this.$store.state.unit;
    }
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/orders/current",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.DBitems = response.data.data.orders;
        this.count = response.data.meta.total;
      })
      .then(() => (this.loading = false))
      .catch(error => {
        if (error.response.status == "401") {
          this.$localStorage.remove(this.$store.storeName);
                    window.location.href="/";

        }
      });
    ///prev
    this.loading1 = true;
    this.axios
      .get(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/orders/previous",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.DBitems1 = response.data.data.orders;
        this.count1 = response.data.meta.total;
      })
      .then(() => (this.loading1 = false))
      .catch(error => {
        if (error.response.status == "401") {
          this.$localStorage.remove(this.$store.storeName);
                    window.location.href="/";

        }
      });
  },
  methods: {
    updatedata() {
      this.unit=this.$store.state.unit;
    if(this.$i18n.locale == 'ar')
    {
        this.unit=this.$store.state.unitAR;
    }
    else
      {
        this.unit=this.$store.state.unit;
    }
      this.loading = true;
     

      ///orders
      this.loading1 = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/orders",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data.orders;
          this.count = response.data.meta.total;
          this.pageSize=response.data.meta.per_page;

        })
        .then(() => (this.loading1 = false))
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
                      window.location.href="/";

          }
        });
      this.$store.state.langchange = false;
    },
    retrieveTutorials() {
      const params = this.page;

      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/orders?page=" +
            params,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data.orders;
          //this.count = response.data.meta.total;
          this.pageSize=response.data.meta.per_page;

        })
        .then(() => (this.loading = false))
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
                      window.location.href="/";

          }
        });
      /*.catch((e) => {
          console.log(params);
          console.log(e);
        });*/
    },
    handlePageChange(value) {
      this.page = value;
      this.retrieveTutorials();
    },
    retrieveTutorials1() {
      const params = this.page1;

      this.loading1 = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/orders?page=" +
            params,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data.orders;
          //this.count = response.data.meta.total;
          this.pageSize=response.data.meta.per_page;

        })
        .then(() => (this.loading1 = false))
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
                      window.location.href="/";

          }
        });
      /*.catch((e) => {
          console.log(params);
          console.log(e);
        });*/
    },
    handlePageChange(value) {
      this.page = value;
      this.retrieveTutorials();
    }
  },
  
  watch:
  {
    langChange()
    {
      this.unit=this.$store.state.unit;
    if(this.$i18n.locale == 'ar')
    {
        this.unit=this.$store.state.unitAR;
    }
    else
      {
        this.unit=this.$store.state.unit;
    }
      this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/orders",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.DBitems = response.data.data.orders;
        this.count = response.data.meta.total;
      })
      .then(() => (this.loading = false))
      .catch(error => {
        if (error.response.status == "401") {
          this.$localStorage.remove(this.$store.storeName);
                    window.location.href="/";

        }
      });
    }
  },
  updated() {
    this.langChange=this.$i18n.locale
  },
};
</script>