<template>
     <!-- Loader End -->
     <section class="md:h-screen py-36 flex items-center bg-orange-500/10 
     dark:bg-orange-500/20 bg-[url('../../theme8/images/hero/bg-shape.png')]
      bg-center bg-no-repeat bg-cover"
      style="margin-top:8% !important"
      >
            <div class="container relative">
                <div class="grid grid-cols-1">
                    <div class="relative overflow-hidden rounded-md shadow dark:shadow-gray-700
                     bg-white dark:bg-slate-900">
                        <div class="grid md:grid-cols-2 grid-cols-1 items-center">
                          <div class="relative md:shrink-0 login_img">
                                <img class="lg:h-full h-full w-full
                                 object-cover md:h-[34rem]" 
                                 :src=this.$store.state.img_login
                                  alt="">
                            </div>

                            <div class="p-8 lg:px-20">
                              <div class="text-center">
                                    <router-link  :to="{
                              name: 'home',
                             params: { storeName: this.$store.storeName,lang: $i18n.locale },
                                     }"
                                     >
                                        <img :src="this.$store.state.logo"  
                                        :alt="this.$store.storeName"
                                        class="mx-auto block dark:hidden logo_login" 
                                        >
                                        <img 
                                        :src="this.$store.state.logo" 
                                         :alt="this.$store.storeName" 
                                        class="mx-auto hidden dark:block logo_login" >
                                    </router-link>
                                </div>

                                <form 
                                 v-if=" this.$store.state.auth==0" 
                                  @submit.prevent="sendEmail"
                                   @keydown="formEmail.onKeydown($event)"
                                class="text-start lg:py-20 py-8">
                                    <div class="grid grid-cols-1">
                                       
        
                                        <div class="mb-4">
                                            <label class="font-semibold" for="LoginEmail">{{ $t('Email') }} *:</label>
                                            <input id="LoginEmail" 
                                            type="email"
                                             class="mt-3 w-full py-2
                                             px-3 h-10 bg-transparent
                                             dark:bg-slate-900 dark:text-slate-200 
                                             rounded outline-none border border-gray-100
                                              dark:border-gray-800 focus:ring-0"
                                             autocomplete="false"
                                              v-model="formEmail.email"
                                              placeholder="example@gmail.com"
                                              equired
                                               >
                                        </div>
        
                                        
        
                                        
        
                                        <div class="mb-4">
                                            <input type="submit"
                                             class="py-2 px-5 inline-block
                                             tracking-wide align-middle duration-500 
                                             text-base text-center bg-orange-500 text-white 
                                             rounded-md w-full" 
                                             :value="$t('register')"
                                             :disabled="formphone.busy"
                                             >
                                        </div>
        
                                        <div class="text-center">
                                             <router-link  
                                             class="text-black dark:text-white
                                              font-bold inline-block title" 
                                              to="/login"> <span class="text-slate-400 me-2">
                                                {{ $t('Already have an account?') }}</span></router-link>
                                        </div>
                                    </div>
                                </form>

                                <form 
                                 v-else
                                  @submit.prevent="sendPhone"
                                   @keydown="formphone.onKeydown($event)"
                                class="text-start lg:py-20 py-8">
                                    <div class="grid grid-cols-1">
                                       
        
                                        <div class="mb-4">
                                            <label class="font-semibold" 
                                            for="Loginphone">{{ $t('Phone') }} *:</label>
                                            <input id="Loginphone" 
                                            type="text"
                                           
                                             class="mt-3 w-full py-2
                                             px-3 h-10 bg-transparent
                                             dark:bg-slate-900 dark:text-slate-200 
                                             rounded outline-none border border-gray-100
                                              dark:border-gray-800 focus:ring-0"
                                              placeholder='xxxx xxxx'
                            autocomplete="false"
                            name="basic_phone"
                            v-model="formphone.basic_phone"
                            required
                           :minlength=$store.state.country_length
                           :maxlength=$store.state.country_length
                            style="text-align: center;"
                                               >
                                               <span class="flag">  
                                <h2>{{ $store.state.country_code }}</h2>
                              <img :src="this.$store.state.country_flag" />
                              
                             
                              </span>
                                        </div>
        
                                        
        
                                        
        
                                        <div class="mb-4">
                                            <input type="submit"
                                             class="py-2 px-5 inline-block
                                             tracking-wide align-middle duration-500 
                                             text-base text-center bg-orange-500 text-white 
                                             rounded-md w-full" 
                                             :value="$t('register')"
                                             :disabled="formphone.busy"
                                             >
                                        </div>
                                        <div v-if="loading" class="empty text-center center-text">
                 
                 <img src="/pet_shop/assets/images/ajax-loader.gif"/>
                           <br>

                       </div>
                                        <div class="text-center">
                                           
                                             <router-link  
                                             class="text-black dark:text-white
                                              font-bold inline-block title" 
                                              to="/login"> <span class="text-slate-400 me-2">
                                              {{ $t('Already have an account?') }}</span>
                                            </router-link>
                                        </div>
                                    </div>
                                </form>

                                <div v-if="validateMessage != ''"
                                 style="text-align: center; margin-top: 10px">
            <span style="color: red; 
            margin: 0">{{ validateMessage }}*</span>
          </div>

       
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section><!--end section -->

</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import Form from "vform";

export default {
components: { VuePhoneNumberInput },
data: function() {
  return {
    isValid: false,
    partphone: null,
    validateMessage: "",
    loading:false,
    formphone: new Form({
      phone: null,
      basic_phone: null,
      
    }),
  
    formEmail: new Form({
      email: this.$localStorage.get(this.$store.register_email),
      phone: null,
    })
  };
},

methods: {
  
  async sendEmail() {
    this.loading=true;
    //if (this.isValid == true) {
      this.$localStorage.set(
                this.$store.register_email,
                this.formEmail.email
              );
    await this.formEmail
      .post(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/genret_code/byemail",
        {
          email: this.formEmail.email,
          //country: this.form.country,
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        }
      )
      .then(response => {
        this.loading=false;
        if (response.data.code == "-1") {
          console.log(response.data.code);
          this.validateMessage = response.data.message;
          setTimeout(() => {
            this.validateMessage = "";
          }, 3000);
        } else if (response.data.code == "1") {
          this.$router.push({
            name: "activateCode",
            params: {
              storeName: this.$store.storeName,
              email: this.formEmail.email
            }
          });
          //console.log( response.data.code);
          //console.log( response.data.message);
        }
      });
    /*} else {
      if (this.$i18n.locale == "en") {
        this.validateMessage = "your number isn't valid";
        setTimeout(() => {
          this.validateMessage = "";
        }, 3000);
      } else {
        this.validateMessage = "هذا الرقم غير صالح";
        setTimeout(() => {
          this.validateMessage = "";
        }, 3000);
      }
    }*/
  }
,
  async sendPhone() {
    this.loading=true;
    this.formphone.phone=this.$store.state.country_code+this.formphone.basic_phone
    //if (this.isValid == true) {
      this.$localStorage.set(
                this.$store.register_phone,
                this.formphone.basic_phone
              );

    await this.formphone
      .post(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/genret_code",
        {
          phone: this.formphone.phone,
          //country: this.form.country,
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        }
      )
      .then(response => {
        this.loading=false;
        if (response.data.code == "-1") {
          this.formphone.phone=null;
          console.log(response.data.code);
          this.validateMessage = response.data.message;
          setTimeout(() => {
            this.validateMessage = "";
          }, 3000);
        } else if (response.data.code == "1") {
        console.log('ppppppp ',this.formphone.phone);
          this.$router.push({
            name: "activateCode",
            params: {
              storeName: this.$store.storeName,
              phone: this.formphone.phone,
            }
          });
          //console.log( response.data.code);
          //console.log( response.data.message);
        }
      });
   
  }
},
created()
{
  this.$store.state.topbar='topbar_dark';
  this.$store.state.navbar='navbar_dark navbar_top';
}
};

</script>