<template>
 <tr :id="productid">
                            	<td class="product-thumbnail_fav text-center center-text"><a href="#"><img :src="image" :alt="name"></a></td>
                                <td class="product-name" style="max-width:100px" :data-title="name"><a href="#">{{ name }}</a></td>
                                <td class="product-price" :data-title="$t('price')">{{ old_price }}</td>
                              	<td v-if="qnt!=0" class="product-stock-status" :data-title="$t('Stock Status')"><span class="badge badge-pill badge-success">{{ $t('instock') }}</span></td>
                                <td v-else class="product-stock-status" :data-title="$t('Stock Status')"><span class="badge badge-pill badge-danger">{{ qnt }}</span></td>

                                <td class="product-add-to-cart">
                                  <a  v-if="qnt!=0" @click="addToCart" class="btn btn-fill-out"><i class="icon-basket-loaded"></i> 
                                    {{ $t('Addtocart') }}</a>

                                    <a v-else > 
                                    ________</a>

                                    <div
      v-if="validateMessage != ''"
      style="
        position: fixed;
        z-index: 100;
        width: 300px;
        bottom: 50px;
        left: 50px;
        border-radius: 5px;
        background-color: #fff;
        border: 2px solid var(--primary-color);
        text-align: center;
        padding-top: 5px;
        color:red
      "
    >
      <p>{{ validateMessage }}</p>
    </div>
                                  </td>
                                <td class="product-remove" :data-title="$t('remove')">
                                    <a @click="changeFav">
                                      <i  @click="changeFav" class="ti-close"></i>
                                      </a>
                                      </td>
                            </tr>
</template>

<script>

export default {
components: { },
props: {
name: String,
is_option: Boolean,
image: String,
old_price: Number,
new_price: Number,
productid: Number,
unit: String,
qnt: Number,
min_qnt:Number,
max_qnt:Number

},
data: function () {
return {
fav: false,
loginMassage: null,
show: false,
validateMessage: '',

};
},
created() {
this.fav = this.is_favorit;
},
methods: {
changeFav() {
    console.log('ffffff')
this.axios
.get(
this.$store.state.base_api_url +
 this.$store.storeName +
 "/favourite_product_change/" +
 this.productid,
{
 headers: {
   "Accept-Language": this.$i18n.locale,
   Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
 },
}
)
.then((response) => {
if (response.data.code == "1") {
 this.fav = !this.fav;
 document.getElementById(this.productid).style="display:none";
}
});
},

////{ path: `/user/${id}` }
productRoute() {
if (this.$route.path.includes("/product")) {
this.$router.push({
name: "product",
params: { id: this.productid, name: this.name,lang: $i18n.locale },
});
} else {
this.$router.push({
name: "product",
params: {
 storeName: this.$store.storeName,
 id: this.productid,
 name: this.name,
 lang: $i18n.locale
},
});
}
},

addToCart() {
console.log('%%%%%');
var checked_true = [];

this.axios
  .post(
    this.$store.state.base_api_url +
      this.$store.storeName +
      "/check_cart_item",
    {
      product_id: this.productid,
      total_price: this.old_price,
      offer_price: this.new_price,
      quantity: 1,
      old_qnt:0,
      final_option: 0,
      option_check: []
    },
    {
      headers: {
        "Accept-Language": this.$i18n.locale
      }
    }
  )
  .then(response => {
    let product = null;
    this.validateMessage = response.data.message;
    setTimeout(() => {
      // this.updateall();
      this.validateMessage = "";
    }, 3000);
    if (response.data.code == "1") {
      // let products = new Map;
      let S_name1 =
        this.$store.storeName +
        "/" +
        this.productid +
        '0' +
        checked_true;
      if (this.$cookies.isKey(S_name1)) {
        /*console.log(
          "this.price " +
            this.price +
            "this.$cookies.get( S_name1 ).total_price" +
            this.$cookies.get(S_name1).total_price
        );*/
        product = {
          product_id: this.productid,
          total_price:
            this.price + this.$cookies.get(S_name1).total_price,
          offer_price:
            this.offer_price + this.$cookies.get(S_name1).offer_price,
          quantity: Number(1) + Number(this.$cookies.get(S_name1).quantity),
          quantityAll: this.$cookies.get(S_name1).quantityAll,
          max_quantity: this.$cookies.get(S_name1).max_quantity,
          min_quantity: this.$cookies.get(S_name1).min_quantity,
          final_option: 0,
          option_check: checked_true,
          discount: this.DBitems.product.discount,
          note:this.$cookies.get(S_name1).note
        };
      } else {
        console.log("hlooooooooooo ::::::::; " + this.qnt);
        product = {
          product_id: this.productid,
          total_price: this.old_price,
          offer_price: this.new_price,
          quantity: Number(1),
          quantityAll: this.qnt,
          min_quantity: this.min_qnt,
          max_quantity: this.max_qnt,
          final_option: 0,
          option_check: checked_true,
          discount: this.discount,
          note: ''
        };
      }
      //check if item is exist on cart or not
      //if (this.$cookies.isKey(this.$store.storeName+this.$route.params.id)) {
      // this.$cookies.remove('storeName')
      // products = this.$cookies.get(this.$store.storeName);
      //}
      //products.set(this.$route.params.id , product);
      let S_name = this.$store.storeName + "/";

      if (
        !this.$cookies.isKey(
          S_name +
            product.product_id +
            product.final_option +
            product.option_check
        )
      ) {
        this.$store.state.cartCounter = this.$store.state.cartCounter + 1;
      }

      this.$cookies.set(
        S_name +
          product.product_id +
          product.final_option +
          product.option_check,
        product
      );
    }
    /*this.$router.push({
      name: "shoppingCart",
      params: {
        storeName: this.$store.storeName,
      },
    });*/

    this.$store.state.update_cart_list=true;

    setTimeout(() => {
      // this.updateall();
      this.validateMessage = "";
    }, 3000);
  });
},
},
};
</script>
<style>
.product-thumbnail_fav img {
width: 100px;
aspect-ratio: 1/1;
object-fit: cover;

}
.vodal-dialog
{
width: 1000px;
height: 450px;
animation-duration: 300ms;
}
.rate
{
font-size:16px !important;
color:#F6BC3E;
}

</style>