<template>

  <div class="container" id="container_order">

    <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>

    <h2 style="text-align: center; ">{{ $t('myOrders') }}</h2>
    <profile_header></profile_header>

    <!--  Edite profile -->


    <div class="container">

      <div style="text-align: center; margin-top: 4%;">
        <button :class="' order_prev button ' + (activeTab ? '' : 'active')" @click="
          activeTab ? (activeTab = !activeTab) : (activeTab = activeTab)
          ">{{ $t('CurrentOrders') }}</button>

        <button :class="'order_prev button prev_orders ' + (activeTab ? 'active' : '')" @click="
          !activeTab ? (activeTab = !activeTab) : (activeTab = activeTab)
          ">{{ $t('PreviousOrders') }}</button>

        <hr class="center_align" style="width: 50%;">
      </div>
      <div v-if="loading" class="d-flex flex-row justify-content-center" style="margin-top: 20%;  ;margin-left:45%">
        <!-- LOADER -->
        <div>
          <hollow-dots-spinner :animation-duration="1000" :dot-size="35" :dots-num="3" color="var(--primary-color)" />

        </div>
      </div>
      <!--  -->
      <div :style="activeTab ? 'display:none' : 'display: block'" id="tab1" data-aos="fade-up" v-if="!loading">
        <div v-if="DBitems.length != 0" class="row">
          <div class="col-lg-4 col-md-6 col-12" v-for="(item, index) in DBitems" :key="index">
            <div @click="$router.push({
              name: 'invoice'
              , params: { storeName: $store.storeName, lang: $i18n.locale, id: item.id }
            })"
              style="border: 1px solid #c9c9c9; margin-top: 2%; margin-bottom: 2%; ">
               
                  <p class="invoice_number">
                    #{{ item.invoice_id }}</p>

                  <div class="order_meta">
                     <div>
                    <span style="color: var(--primary-color)"> {{ $t('total') }}:</span>
                    <span class="order_state">{{ item.total_price * $store.state.rate }} {{ $store.state.UnitPrice }}</span>

                  </div>

                  <div>
                    <span style="color: var(--primary-color)">{{ $t('status') }}:</span>
                    <span class="order_state">{{ item.state }}</span>

                  </div>
                  <div>
                    <span style="color: var(--primary-color)">{{ $t('Time and Date') }}:</span>
                    <span class="order_date">{{ item.time }}</span>

                  </div>
                  <button class="butt_green but_order center_align">{{ $t('show order') }}</button>

                  </div>
                 

                </div>
          </div>

        </div>
        <div v-else class="empty text-center center-text ">

          <img src="/assets/images/empty.png" />

          <br>

          {{ $t('empty') }}
        </div>
        <div class="d-flex flex-row justify-content-center mt-5" v-if="this.count > 1">
          <b-pagination v-model="page" :total-rows="count" :per-page="pageSize" :prev-text="$t('prev')"
            :next-text="$t('next')" pills @change="handlePageChange"></b-pagination>
        </div>
      </div>
      <!--  -->


      <!--  -->
      <div id="tab2" :style="activeTab ? 'display:block' : 'display:none'" style="display: none" data-aos="fade-up"
        v-if="!loading1">
        <div v-if="DBitems1.length != 0" class="row">
          <div class="col-lg-6 col-md-6 col-12" v-for="(item, index) in DBitems1" :key="index">
            <div @click="$router.push({
              name: 'invoice'
              , params: { storeName: $store.storeName, lang: $i18n.locale, id: item.id }
            })"
              style="border: 1px solid #c9c9c9; margin-top: 2%; margin-bottom: 2%; ">
              <div class="row">
                <div class="col-lg-4 col-md-4 col-6">
                  <div style=" width: 160px;
                                height: 225px;
                                
                                margin: 14% 14%;;">
                    <img :src=$store.state.logo style="width: 150px;
                                    object-fit:cover;padding-top: 9%;  ">
                  </div>


                </div>
                <div class="col-lg-8 col-md-8 col-6" style="padding-bottom: 2%; padding-top: 2%;">
                  <p class="invoice_number">
                    #{{ item.invoice_id }}</p>
                  <p class="invoice_number">x{{ item.count_items }}</p>

                  <div class="order_meta">
                    <h6>{{ $store.state.storeName }}</h6>
                    <h6 style="color: var(--primary-color) ;font-size: 13px;font-weight: 400;">
                      {{ item.total_price * $store.state.rate }} {{ $store.state.UnitPrice }}</h6>

                  </div>
                  <div>
                    <span style="color: var(--primary-color)">{{ $t('status') }}:</span>
                    <span class="order_state">{{ item.state }}</span>

                  </div>
                  <div>
                    <span style="color: var(--primary-color)">{{ $t('Time and Date') }}:</span>
                    <span class="order_state">{{ item.date }}</span>

                  </div>

                  <button class="butt_green but_order center_align">{{ $t('show order') }}</button>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div v-else class="empty text-center center-text ">

          <img src="/assets/images/empty.png" />

          <br>

          {{ $t('empty') }}
        </div>
        <div class="d-flex flex-row justify-content-center mt-5" v-if="this.count1 > 1">
          <b-pagination v-model="page1" :total-rows="count1" :per-page="pageSize1" :prev-text="$t('prev')"
            :next-text="$t('next')" pills @change="handlePageChange1"></b-pagination>
        </div>

      </div>

      <!--  -->



    </div>



  </div>

</template>

<script>

import profile_header from "../components/profile_header.vue";
import { HalfCircleSpinner, HollowDotsSpinner, CirclesToRhombusesSpinner } from "epic-spinners";

export default {
  data: function () {
    return {
      DBitems: [],
      DBitems1: [],

      loading: false,
      loading1: false,

      activeTab: false,
      page: 1,
      count: 0,
      pageSize: 6,

      page1: 1,
      count1: 0,
      pageSize1: 6

      // langChange:""
    };
  },
  components: {
    profile_header,
    HalfCircleSpinner,
    HollowDotsSpinner,
    CirclesToRhombusesSpinner

  },
  created() {
    if (this.$route.params.lang && this.$route.params.lang != this.$i18n.locale) {
      this.$i18n.locale = this.$route.params.lang; this.$store.state.UnitPrice = this.$store.state.unitAR
      if (this.$i18n.locale == 'en') {
        document.documentElement.setAttribute("dir", "ltr");
        let h = document.querySelector('body');
      }
    }

    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url +
        this.$store.storeName +
        "/orders/current",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.DBitems = response.data.data.orders;
        this.count = response.data.meta.total;
      })
      .then(() => (this.loading = false))
      .catch(error => {
        // if (error.response.status == "401") {
        //   this.$localStorage.remove(this.$store.storeName);
        //             window.location.href="/";

        // }
      });

    ///prev
    this.loading1 = true;
    this.axios
      .get(
        this.$store.state.base_api_url +
        this.$store.storeName +
        "/orders/previous",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.DBitems1 = response.data.data.orders;
        this.count1 = response.data.meta.total;
      })
      .then(() => (this.loading1 = false))
      .catch(error => {
        if (error.response.status == "401") {
          this.$localStorage.remove(this.$store.storeName);
          window.location.href = "/";

        }
      });

    if (this.$i18n.locale == 'ar') {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content = this.$store.state.description_ar;
      document.getElementById("keywords_meta").content = this.$store.state.keyword_ar;
    }
    else {
      document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content = this.$store.state.description_en;
      document.getElementById("keywords_meta").content = this.$store.state.keyword_en;
    }
  },
  methods: {
    updatedata() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/orders/current",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data.orders;
          this.count = response.data.meta.total;
        })
        .then(() => (this.loading = false))
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href = "/";

          }
        });

      ///prev
      this.loading1 = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/orders/previous",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.DBitems1 = response.data.data.orders;
          this.count1 = response.data.meta.total;
          this.pageSize = response.data.meta.per_page;

        })
        .then(() => (this.loading1 = false))
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href = "/";

          }
        });
      this.$store.state.langchange = false;
    },
    retrieveTutorials() {
      const params = this.page;

      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/orders/current?page=" +
          params,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data.orders;
          //this.count = response.data.meta.total;
          this.pageSize = response.data.meta.per_page;

        })
        .then(() => (this.loading = false))
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href = "/";

          }
        });
      /*.catch((e) => {
          console.log(params);
          console.log(e);
        });*/
    },
    handlePageChange(value) {
      this.page = value;
      this.retrieveTutorials();
    },
    retrieveTutorials1() {
      const params = this.page1;

      this.loading1 = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/orders/previous?page=" +
          params,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.DBitems1 = response.data.data.orders;
          //this.count = response.data.meta.total;
          this.pageSize = response.data.meta.per_page;

        })
        .then(() => (this.loading1 = false))
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href = "/";

          }
        });
      /*.catch((e) => {
          console.log(params);
          console.log(e);
        });*/
    },
    handlePageChange1(value) {
      this.page1 = value;
      this.retrieveTutorials1();
    }
  }
};
</script>