<!-- START HEADER -->
<template>
  <section>
    <div
        v-if="loading"
        class="d-flex flex-row justify-content-center"
        style="margin-top: 100px;
         margin-bottom: 200px"
      >
        <!-- LOADER -->
<div class="preloader">
    <div class="lds-ellipsis">
        <span></span>
        <span></span>
        <span></span>
    </div>
</div>
<!-- END LOADER -->

      </div>

<header v-else class="header_wrap fixed-top header_with_topbar">
	<div class="top-header">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-6">
                	<div class="d-flex align-items-center justify-content-center justify-content-md-start">
                        <div class="lng_dropdown mr-2">
                          <a v-if="this.$i18n.locale=='ar'" style="cursor:pointer" @click="onChange('en')">
                          <img src="/pet_shop/assets/images/eng.png" class="langimg"/>
                                    English
                                    </a>

                                    <a v-else @click="onChange('ar')" style="cursor:pointer">
                          <img :src="this.$store.state.country_flag" class="langimg" />
                                    العربية
                                    </a>
                        </div>
                       
                        <ul v-if="this.$store.state.phone" class="contact_detail text-center text-lg-left" >
                            <li><i class="ti-mobile"></i><span dir="ltr">{{ this.$store.state.phone }}</span></li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6">
                	<div class="text-center text-md-right">
                       	<ul class="header_list">
                            <li v-if="this.$localStorage.get(this.$store.storeName) != null"><router-link to="/favorites"><i class="ti-heart"></i><span>{{ $t('Wishlist') }}</span></router-link></li>
                            <li v-if="this.$localStorage.get(this.$store.storeName) == null"><router-link to="/login"><i class="ti-user"></i><span>{{ $t('logIn') }}</span></router-link></li>
                            <li
               
                v-if="this.$localStorage.get(this.$store.storeName) != null"
              >
                <router-link
                  :to="{
                  name: 'home',
                  params: { storeName: this.$store.storeName,lang: $i18n.locale },
                }"
                @click.native="show2 = true"
                > <i class="ti-user"></i> {{ $t("logOut") }}</router-link>
              </li>
                       </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bottom_header dark_skin main_menu_uppercase">
    	<div class="container">
            <nav class="navbar navbar-expand-lg"> 
                <router-link class="navbar-brand" to="/">
                    <img class="logo_light" :src="this.$store.state.logo" :alt="this.$store.state.storeName" />
                    <img class="logo_dark" :src="this.$store.state.logo" :alt="this.$store.state.storeName" />
                </router-link>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-expanded="false"> 
                    <span class="ion-android-menu"></span>
                </button>
                <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="dropdown">
                            <router-link data-toggle="dropdown" class="nav-link nav_item " to="/">{{ $t('home') }}</router-link>
                           
                        </li>


                        
                        

                          <!-- products -->
                          <li >
                            <router-link class="nav_item nav-link"
                             to="/products">{{ $t('products') }}</router-link>
                             
                        </li>
                         <!-- end products -->

<!-- first 3 section -->
<template  v-for="(item, index) in this.sections">
                        <li  
                         :key="index"
                        v-if="item.categories.length>2"
                        class="dropdown dropdown-mega-menu"
                        
                        :id="'section'+index"

                      
                        >
                            <router-link class="dropdown-toggle nav-link" 
                            :to="{
                              name: 'section',
                              params: { storeName: $store.storeName, id: item.id  ,
                               name:item.name
                               ,lang: $i18n.locale},
                                  }"
      
                            data-toggle="dropdown">{{ item.name }}</router-link>
                             <div class="dropdown-menu">
                                <ul class="mega-menu d-lg-flex">
                                    <li class="mega-menu-col col-lg-3"
                                    v-for="(item2, index) in item.categories"
                                   :key="index"
                                    >
                                        <ul> 
                                            <li class="dropdown-header">{{ item2.label }}</li>
                                            <li
                                            v-for="(product, index) in item2.products"
                                             :key="index"
                                            >
                                              <router-link 
                                              class="dropdown-item nav-link nav_item" 
                                              :to="{
                              name: 'product',
                              params: { storeName: $store.storeName, id: product.id  , name:product.name,lang: $i18n.locale},
                                  }">{{ product.name }}</router-link>
                                              </li>
                                           
                                        </ul>
                                    </li>
                                   
                                </ul>
                                
                            </div>
                        </li>

                        <li  
                         :key="index"
                         v-else
                        class="dropdown"
                        
                        :id="'section'+index"

                      
                        >
                            <router-link class="dropdown-toggle nav-link" 
                            :to="{
                              name: 'section',
                              params: { storeName: $store.storeName, id: item.id  ,
                               name:item.name,lang: $i18n.locale},
                                  }"
      
                            data-toggle="dropdown">{{ item.name }}</router-link>
                             <div v-if="item.categories.length==2" class="dropdown-menu item_div">
                                <ul class="mega-menu d-lg-flex">
                                    <li class="mega-menu-col col-lg-6"
                                    v-for="(item2, index) in item.categories"
                                   :key="index"
                                    >
                                        <ul> 
                                            <li class="dropdown-header">{{ item2.label }}</li>
                                            <li
                                            v-for="(product, index) in item2.products"
                                             :key="index"
                                            >
                                              <router-link 
                                              class="dropdown-item nav-link nav_item" 
                                              :to="{
                              name: 'product',
                              params: { storeName: $store.storeName, id: product.id  , name:product.name},
                                  }">{{ product.name }}</router-link>
                                              </li>
                                           
                                        </ul>
                                    </li>
                                   
                                </ul>
                                
                            </div>









                            <div v-else class="dropdown-menu">
                                <ul class="mega-menu d-lg-flex">
                                    <li class="mega-menu-col col-lg-6"
                                    v-for="(item2, index) in item.categories"
                                   :key="index"
                                    >
                                        <ul> 
                                            <li class="dropdown-header">{{ item2.label }}</li>
                                            <li
                                            v-for="(product, index) in item2.products"
                                             :key="index"
                                            >
                                              <router-link 
                                              class="dropdown-item nav-link nav_item" 
                                              :to="{
                              name: 'product',
                              params: { storeName: $store.storeName, id: product.id  , name:product.name},
                                  }">{{ product.name }}</router-link>
                                              </li>
                                           
                                        </ul>
                                    </li>
                                   
                                </ul>
                                
                            </div>
                        </li>
                        </template>

                        <li>
                            <router-link class="nav_item nav-link" to="/sections" >
                              {{ $t('All Sections') }}
                              </router-link>
                            
                        </li>
                        <li><router-link class="nav-link nav_item" 
                        to="/contactUs">{{ $t('contactUs') }}
                          </router-link></li> 
                    </ul>
                </div>
                <!-- cart -->
                <ul class="navbar-nav attr-nav align-items-center">
                    <li><a  @click="open_search()" class="nav-link search_trigger"><i class="linearicons-magnifier"></i></a>
                        <div class="search_wrap" id="search_wrap">
                            <span class="close-search" >
                              <i  @click="close_search()" class="ion-ios-close-empty"></i></span>
                            <div>
                                <input
                                  class="form-control"
                                   id="search-input"
                    autocomplete="false"
                    onblur="this.setAttribute('readonly', 'readonly') , word=''"
                    onfocus="this.removeAttribute('readonly');"
                    :placeholder="$t('search')"
                    :aria-label="$t('search')"
                    aria-describedby="search-addon"
                   type="text"
                    v-model="word"
                    @keyup.enter="searchRoute"
                                   >
                                <button  class="search_icon" @click="searchRoute">
                                  <i class="ion-ios-search-strong"></i>
                                  </button>
                            </div>
                        </div><div class="search_overlay" id="search_overlay"></div>
                    </li>
                    <li class="dropdown cart_dropdown">
                      
                      <router-link  class="nav-link cart_trigger" to="/shopping_cart" data-toggle="dropdown"><i class="linearicons-cart"></i>
                    <span class="cart_count">{{ this.$store.state.cartCounter }}</span></router-link>
                       <!-- <cart_list>
                        </cart_list> -->
                    </li>
                </ul>
                <!-- end cart -->
            </nav>
        </div>
    </div>
</header>
<Transition name="modal">
      <div v-if="show" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">{{ $t("confirmdeleteAllNoti") }}</slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button" @click="deleteAll()">{{ $t("confirm") }}</button>
                <button
                  class="modal-default-button"
                  @click="(show = false), $emit('close')"
                >{{ $t("cancel") }}</button>
              </slot>
            </div>
          </div>
        </div>
      </div>
      <div v-if="show1" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">{{ $t("confirmdeleteOneNoti") }}</slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button" @click="deleteOneNote()">{{ $t("confirm") }}</button>
                <button
                  class="modal-default-button"
                  @click="(show1 = false), $emit('close')"
                >{{ $t("cancel") }}</button>
              </slot>
            </div>
          </div>
        </div>
      </div>

      <div v-if="show2" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">{{ $t("confirmlogout") }}</slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button" @click="logOut()">{{ $t("confirm") }}</button>
                <button
                  class="modal-default-button"
                  @click="(show2 = false), $emit('close')"
                >{{ $t("cancel") }}</button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </Transition>
</section>
</template>
<!-- END HEADER -->

<script>
import cart_list from "../components/cart_list.vue";

export default {
  components: { cart_list},
  // eslint-disable-next-line
  /*eslint-disable */
  name: "Header",
  props: {
    // name: String,
    // image: String
    storeName: String
  },
  data: function() {
    return {
      lang:'en',
      loading: true,
      loadingNote: false,
      activeMobile: false,
      word: "",
      removeClass: false,
      show: false,
      show1: false,
      show2: false,
      deleteNoteId: null,
      deleteNoteIndex: null,
      notifications: [],
      sections:[],
      loading: false,
    };
  },
  computed: {
    myState: function() {
      return this.$store.state.notificationUpdate; // return the state value in `my_state`
    }
  },
  watch: {
    myState: function(newVal, oldVal) {
      console.log(this.$store.notificationUpdate + " hiiiiiiiiiiii ");
      if (this.$localStorage.get(this.$store.storeName) != null) {
        this.loadingNote = true;
        this.axios
          .get(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/new_note",
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
                Authorization:
                  "Bearer " + this.$localStorage.get(this.$store.storeName)
              }
            }
          )
          .then(response => {
            if (response.data.data.code == "1") {
              this.notifications = response.data.data.notes;
              console.log("heeeeeeeeeeeeeeeeeeee " + this.notifications);
            }
          })
          .then((this.loadingNote = false));
      }
    }
  },
  created() {
    if (this.$route.params.lang && this.$route.params.lang != this.$i18n.locale) {
      this.$i18n.locale = this.$route.params.lang; 
      this.$store.state.UnitPrice = this.$store.state.unitAR
      if (this.$i18n.locale == 'en') {
        document.getElementById("style").href = '/pet_shop/assets/css/style.css';
        document.getElementById("stylertl").href = '/pet_shop/assets/css/rtl-style.css';
        // document.getElementById("respoinsive").href = 'assetsrtl/css/responsive.css';
        // document.getElementById("allmin").href = 'assetsrtl/css/all.min.css';
        document.getElementById("bootstrap").href = '/pet_shop/assets/bootstrap/css/bootstrap.min.css';
        let h = document.querySelector('html');
        h.setAttribute('lang', 'ar');
        this.$store.state.UnitPrice = this.$store.state.unit
      }
    
    }
    this.loading=true;
    if (this.$localStorage.get(this.$store.storeName) != null) {
      this.loadingNote = true;
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/new_note",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.data.code == "1") {
            this.notifications = response.data.data.notes;
            //console.log(this.notifications);
          }
        })
        .then((this.loadingNote = false));
    }
    let AllCookies = this.$cookies.keys();
    let counter = 0;
    for (let i = 0; i < AllCookies.length; i++) {
      if (AllCookies[i].split("/")[0] == this.$store.storeName) {
        counter++;
      }
    }
    this.$store.state.cartCounter = counter;
    console.log(counter);

  
        this.axios
          .get(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/theme5/header/sections",
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
              },
            }
          )
          .then((response) => {
            this.sections = response.data.data;
            console.log('sections',this.sections)
          });
         
          this.$store.state.langchange = false;

          this.loading=true;

          //add class to li

          for(var i=0;i<this.sections.length;i++)
          {
            if(this.sections.categories.length>2)
            {
              document.getElementByid('section'+i).classList.add('dropdown-mega-menu');
            }
          }
          this.loading=false;

  },
  methods: {
  
    onChange(lang2) {
      this.$store.state.langchange = true;

      this.$store.state.langchange1 = true; //for footer
      this.$store.state.langchange_check = true;
      this.$router.replace({ params: { lang: this.$i18n.locale } })

      this.$i18n.locale = lang2;
      if (this.$i18n.locale == "ar") {
      
        this.$store.state.UnitPrice=this.$store.state.unitAR


        document.getElementById("style").href = '/pet_shop/assetsrtl/css/style.css';
        document.getElementById("stylertl").href = '/pet_shop/assetsrtl/css/rtl-style.css';
        // document.getElementById("respoinsive").href = 'assetsrtl/css/responsive.css';
        // document.getElementById("allmin").href = 'assetsrtl/css/all.min.css';
        document.getElementById("bootstrap").href = '/pet_shop/assetsrtl/bootstrap/css/bootstrap.min.css';
        let h = document.querySelector('html');
        h.setAttribute('lang', 'ar');
        document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      
      var element = document.querySelector("body");
  element.classList.add("rtl");
  if(element.classList.contains('ltr'))
  {
    element.classList.remove("ltr");
  }
      }

      
      else {
        this.$store.state.UnitPrice=this.$store.state.unit

        let h = document.querySelector('html');
        h.setAttribute('lang', 'en');

        // let h2 = document.querySelector('body');
        // h2.setAttribute('dir', 'ltr');
        
         document.getElementById("style").href = '/pet_shop/assets/css/style.css';
         document.getElementById("stylertl").href = '/pet_shop/assets/css/rtl-style.css';
        // document.getElementById("respoinsive").href = 'assets/css/responsive.css';
        // document.getElementById("allmin").href = 'assets/css/all.min.css';
        document.getElementById("bootstrap").href = '/pet_shop/assets/bootstrap/css/bootstrap.min.css';
      
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      var element = document.querySelector("body");
  element.classList.add("ltr");
  if(element.classList.contains('rtl'))
  {
    element.classList.remove("rtl");
  }
      
    }
      if (this.$localStorage.get(this.$store.storeName) != null) {
        this.loadingNote = true;
        this.axios
          .get(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/new_note",
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
                Authorization:
                  "Bearer " + this.$localStorage.get(this.$store.storeName)
              }
            }
          )
          .then(response => {
            if (response.data.data.code == "1") {
              this.notifications = response.data.data.notes;
              //console.log(this.notifications);
            }
          })
          .then((this.loadingNote = false));
      }

      this.loading = true;
        this.axios
          .get(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/theme5/header/sections",
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
              },
            }
          )
          .then((response) => {
            this.sections = response.data.data;
            console.log('sections',this.sections)
          })
          .then(() => (this.loading = false));
          this.$store.state.langchange = false;

    },
    logOut() {
      this.loading = true;
      this.axios.get(
        this.$store.state.base_api_url + this.$store.storeName + "/logout",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      );
      //console.group("hiiiiii")
      console.log(
        "logout:" +
          //this.$localStorage.id() +
          this.$localStorage.get(this.$store.storeName)
      );
      //this.$localStorage.remove("userToken");
      this.show2=false;
      let AllCookies = this.$cookies.keys();
      for (let i = 0; i < AllCookies.length; i++) {
        console.log('hhhhhhhhhhhhhhh111');
        if (AllCookies[i].split("/")[0] == this.$store.state.storeName) {
          this.$cookies.remove(console.log(AllCookies[i]));
        
        }
        this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))

      }
      console.log(AllCookies);
      /*if (this.$cookies.isKey(this.$store.storeName + product.product_id + product.final_option + product.option_check)) {
      this.$cookies.remove(this.$store.storeName + product.product_id + product.final_option + product.option_check)
      }*/
      this.$localStorage.remove(this.$store.storeName);
      window.location.reload();
      this.loading = false;
    },
    searchRoute() {
      if (this.$route.path.includes("/" + this.$store.storeName + "/search")) {
        console.log(this.word + "hiiiiiii");
        this.$router.push({ name: "search", params: { word: this.word } });
      } else {
        this.$router.push({
          name: "search",
          params: { storeName: this.$store.storeName, word: this.word }
        });
      }
    },
    deleteAll() {
      this.loadingNote = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/delete_allnote",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.code == "1") {
            this.notifications = null;
            this.count = 0;
          }
        })
        .then(() => {
          this.loadingNote = false;
          this.show = false;
        });
    },
    deleteOneNote() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/delete_note/" +
            this.deleteNoteId,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.code == "1") {
            this.$delete(this.notifications, this.deleteNoteIndex);

            // this.$el.parentNode.removeChild(this.$el);
          }
        })
        .then(() => {
          this.loading = false;
          this.show1 = false;
          this.show2 = false;
        });
    },
    deleteNote(itemId, itemIndex) {
      this.show1 = true;
      this.deleteNoteId = itemId;
      this.deleteNoteIndex = itemIndex;
    }
,
close_search()
{
  document.getElementById('search_wrap').classList.remove('open')
     document.getElementById('search_overlay').classList.remove('open')

     document.getElementById('search_wrap').style="visibility:hidden ;opacity: 0"
     document.getElementById('search_overlay').style="width:0%"
  },
  open_search()
  {
     document.getElementById('search_wrap').classList.add('open');
     document.getElementById('search_wrap').style="visibility:visible ;opacity: 1"
     document.getElementById('search_overlay').classList.add('open'),
     document.getElementById('search_overlay').style="width:100%"
     console.log('iddddddd',document.getElementById('search_wrap').classList)

	
		}
	
		
	//	this.removeClass = true;
,	
  

   
  }
};


</script>
<style>
.dark_skin .logo_light,
 .dark_skin .logo_default,
  .light_skin .logo_default,
   .light_skin .logo_dark,
    .logo_light,
     .logo_dark {
    display: none;
    max-height: 100px;
    max-width: 200px;
}

.navbar .navbar-nav li.dropdown-mega-menu .dropdown-menu {
    right: 0;
    padding-left: 0;
    padding-right: 0;
    top: 70%;
}
.langimg{
  border-radius: 50%;
    width: 20px;
    height: 20px
}
.item_div
{
  min-width:500px !important
}
.router-link-exact-active
{
  color:var(--primary-color);
}


</style>