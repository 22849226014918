<template>
    <div class="card product_card">
                       <img :src=image
                       :alt="name"
                       loading="lazy"
                       class="one_product_image"
                       >
                       <div class="top-right2" >
                           <a  @click="changeFav" >
                               <i v-if="this.fav" id="faPlus" class=" fa fa-heart font-xs love" style="color:red"></i>
                               <i v-else id="faPlus" class=" fa fa-heart-o font-xs love"></i>

                             </a>
                              </div>
                              <div class="top-left" >
                             <a  v-if="this.login" 
                             href="javascript:void(0)"
                                class="login_product_msg">{{ $t('loginMessage1') }} {{ $t('loginMessage2') }}
                               </a>
                               <a  v-if="this.msg_fav"
                                href="javascript:void(0)"
                                class="login_product_msg">
                                {{ this.msg_fav}}
                               </a>
                       </div>
                       <h6 class="title_card">{{name}}</h6>
                       <div class="d-flex justify-content-between desc_card">
                         <p v-if="is_option">{{$t('PriceOnRequest')}}</p>
                               <p v-else-if="(new_price>0)&&(!is_option)">{{ new_price.toFixed(2) }}{{ unit }}
                                   <del class="text-slate-400">{{ old_price.toFixed(2) }}{{ unit }}</del></p>
                               
                               <p v-else-if="!is_option">{{ old_price.toFixed(2) }} {{ unit }}</p>
                           <router-link  
                           :to="{
                                name: 'product',
                                params: {
                                storeName: $store.storeName,
                                lang: $i18n.locale,
                                 id: productid  ,
                                  name:name
                                           },
                                  }" style="margin-right: 4%;">
                           <button class="add_cart_btn "><i class="fa fa-plus"></i></button>
                       </router-link>
                       </div>

                   </div>
   </template>
  <script>
  import feather from 'feather-icons';
  import  'feather-icons'
  
  export default {
    components: {},
    props: {
      qnt: Number,
      min_qnt: Number,
      max_qnt: Number,
      name: String,
      description: String,
      is_favorit: Boolean,
      is_option: Boolean,
      image: String,
      old_price: Number,
      new_price: Number,
      productid: Number,
      count_view: Number,
      unit: String,
      rate: Number,
      is_offer: Boolean,
      discount: Number,
      new:Boolean,
      id:Number,
      fetuered:Boolean
      
  
    },
    data: function () {
      return {
        fav: false,
        loginMassage: null,
        show: false,
        validateMessage: '',
        finalOptions:0,
      };
    },
    computed: {
      heart: function () {
        return feather.toSvg('heart')
      },
      eye: function () {
        return feather.toSvg('eye')
      },
    },
    created() {
      this.fav = this.is_favorit;
    },
    methods: {
      changeFav() {
        this.axios
          .get(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/favourite_product_change/" +
              this.productid,
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
                Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
              },
            }
          )
          .then((response) => {
            if (response.data.code == "1") {
              this.fav = !this.fav;
              if(this.fav==false)
              {document.getElementById('fav'+this.id).style='display:none'}
              else
              {document.getElementById('fav'+this.id).style='display:block'}
            }
          });
      },
     
      ////{ path: `/user/${id}` }
      productRoute() {
        if (this.$route.path.includes("/product")) {
          this.$router.push({
            name: "product",
            params: { id: this.productid,lang: $i18n.locale, name: this.name },
          });
        } else {
          this.$router.push({
            name: "product",
            params: {
              storeName: this.$store.storeName,
              lang: $i18n.locale,
              id: this.productid,
              name: this.name,
            },
          });
        }
      },
  
      addToCart() {
  console.log('%%%%%');
  var checked_true = [];
  
  this.axios
    .post(
      this.$store.state.base_api_url +
        this.$store.storeName +
        "/check_cart_item",
      {
        product_id: this.productid,
        total_price: this.old_price,
        offer_price: this.new_price,
        quantity: 1,
        old_qnt:0,
        final_option: 0,
        option_check: []
      },
      {
        headers: {
          "Accept-Language": this.$i18n.locale
        }
      }
    )
    .then(response => {
      let product = null;
      this.validateMessage = response.data.message;
      setTimeout(() => {
        // this.updateall();
        this.validateMessage = "";
      }, 3000);
  
      if (response.data.code == "1") {
        // let products = new Map;
        let S_name1 =
          this.$store.storeName +
          "/" +
          this.productid +
          '0' +
          checked_true;
        if (this.$cookies.isKey(S_name1)) {
        
          product = {
            product_id: this.productid,
            total_price:
              this.price + this.$cookies.get(S_name1).total_price,
            offer_price:
              this.offer_price + this.$cookies.get(S_name1).offer_price,
            quantity: Number(1) + Number(this.$cookies.get(S_name1).quantity),
            quantityAll: this.$cookies.get(S_name1).quantityAll,
            max_quantity: this.$cookies.get(S_name1).max_quantity,
            min_quantity: this.$cookies.get(S_name1).min_quantity,
            final_option: 0,
            option_check: checked_true,
            discount: this.DBitems.product.discount,
            note:this.$cookies.get(S_name1).note
          };
        } else {
          console.log("hlooooooooooo ::::::::; " + this.qnt);
          product = {
            product_id: this.productid,
            total_price: this.old_price,
            offer_price: this.new_price,
            quantity: Number(1),
            quantityAll: this.qnt,
            min_quantity: this.min_qnt,
            max_quantity: this.max_qnt,
            final_option: 0,
            option_check: checked_true,
            discount: this.discount,
            note: ''
          };
        }
        //check if item is exist on cart or not
        //if (this.$cookies.isKey(this.$store.storeName+this.$route.params.id)) {
        // this.$cookies.remove('storeName')
        // products = this.$cookies.get(this.$store.storeName);
        //}
        //products.set(this.$route.params.id , product);
        let S_name = this.$store.storeName + "/";
  
        if (
          !this.$cookies.isKey(
            S_name +
              product.product_id +
              product.final_option +
              product.option_check
          )
        ) {
          this.$store.state.cartCounter = this.$store.state.cartCounter + 1;
        }
  
        this.$cookies.set(
          S_name +
            product.product_id +
            product.final_option +
            product.option_check,
          product
        );
      }
      /*this.$router.push({
        name: "shoppingCart",
        params: {
          storeName: this.$store.storeName,
        },
      });*/
  
      this.$store.state.update_cart_list=true;
  
      setTimeout(() => {
        // this.updateall();
        this.validateMessage = "";
      }, 3000);
    });
  },
    },
  };
  </script>
   