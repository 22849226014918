<template>
    <section 
  
    v-if="this.$localStorage.get(this.$store.storeName) == null"
    class="md:h-screen py-36 flex
     items-center bg-orange-500/10 dark:bg-orange-500/20 
     bg-[url('../../theme8/images/hero/bg-shape.png')] bg-center 
     bg-no-repeat bg-cover"
     style="margin-top:8% !important"
     >
            <div class="container relative" style="margin-top:10% !important">
                <div class="grid grid-cols-1">
                    <div class="relative overflow-hidden rounded-md shadow
                     dark:shadow-gray-700 bg-white dark:bg-slate-900">
                        <div class="grid md:grid-cols-2 grid-cols-1 items-center">
                            <div class="relative md:shrink-0 login_img">
                                <img class="lg:h-full h-full w-full
                                 object-cover md:h-[34rem] " 
                                 :src=this.$store.state.img_login
                                  alt="">
                            </div>
                            <div class="p-8 lg:px-20">
                                <div class="text-center">
                                    <router-link 
                              :to="{
                              name: 'home',
                             params: { storeName: this.$store.storeName,lang: $i18n.locale },
                                     }"
                                     >
                                        <img :src="this.$store.state.logo"  
                                        :alt="this.$store.storeName"
                                        class="mx-auto block dark:hidden logo_login" 
                                        >
                                        <img 
                                        :src="this.$store.state.logo" 
                                         :alt="this.$store.storeName" 
                                        class="mx-auto hidden dark:block logo_login" >
                                    </router-link>
                                </div>
                                <div v-if="errorMessage != ''" 
                                style="text-align: center; color: red; padding: 5px">
                            <p style="margin: 0">{{ errorMessage }}</p>
                                   </div>

                                <form  
                                v-if=" this.$store.state.auth==0"
                               @submit.prevent="submitInfo"
                                @keydown="form.onKeydown($event)"
                                class="text-start lg:py-20 py-8">
                                    <div class="grid grid-cols-1">
                                        <div class="mb-4">
                                            <label 
                                            class="font-semibold" 
                                            for="Loginemail">
                                            {{ $t('Email') }}:</label>
                                            <input id="Loginemail" 
                                            type="text" 
                                            class="mt-3 w-full py-2 px-3 
                                            h-10 bg-transparent dark:bg-slate-900
                                             dark:text-slate-200 rounded outline-none
                                              border border-gray-100 dark:border-gray-800 
                                              focus:ring-0" 
                                              v-model="form.email"
                                   placeholder="example@gmail.com"
                                    required
                                             >
                                            
                              
                                        </div>
        
                                        <div class="mb-4">
                                            <label 
                                            class="font-semibold" 
                                            for="LoginPassword"
                                            >{{ $t('password') }}:</label>
                                            <input 
                                            id="LoginPassword" 
                                            type="password" 
                                            class="mt-3 w-full py-2 
                                            px-3 h-10 bg-transparent 
                                            dark:bg-slate-900 dark:text-slate-200 
                                            rounded outline-none border border-gray-100
                                             dark:border-gray-800 focus:ring-0" 
                                             v-model="form.password"
                                  :placeholder="$t('EnterPass')"
                                  required
                                  minlength="8"
                                             >
                                        </div>
        
                                        <div class="flex justify-between mb-4">
                                            <div class="flex items-center mb-0">
                                                <input class="form-checkbox rounded border-gray-100 dark:border-gray-800 text-orange-500 focus:border-orange-300 focus:ring focus:ring-offset-0 focus:ring-orange-200 focus:ring-opacity-50 me-2" type="checkbox" value="" id="RememberMe">
                                                <label class="form-checkbox-label text-slate-400" for="RememberMe">{{ $t('Remember me') }}</label>
                                            </div>
                                            <p class="text-slate-400 mb-0">
                                            <a class="text-slate-400"
                                             @click="(forgetVodal = true), (phone = null), (partphone = null)"
                                             >{{$t('forgetpass')}}</a></p>
                                        </div>
        
                                        <div class="mb-4">
                                            <input type="submit" :disabled="form.busy"
                                             class="py-2 px-5 inline-block tracking-wide
                                              align-middle duration-500 text-base text-center
                                               bg-orange-500 text-white rounded-md w-full" 
                                               :value="$t('login')">
                                        </div>
                                        <div v-if="loading" class="empty text-center center-text">
                 
                 <img src="/pet_shop/assets/images/ajax-loader.gif"/>
                           <br>

                       </div>
                                        <div class="text-center">
                                            <span class="text-slate-400 me-2">
                                                {{ $t("dontHaveAccount") }}</span> 
                                                <router-link
             
             :to="{
             name: 'register',
             params: { storeName: this.$store.storeName,lang: $i18n.locale },
           }"
    class="text-black dark:text-white font-bold inline-block">{{$t('signup')}}</router-link>
                                        </div>
                                    </div>
                                </form>
                                
                                <div v-else>
      <!-- phone -->
      <div v-if="this.$store.state.sms_type=='0'">
        <!--firebase-->
        {{ this.$router.push({
        name: "logInPhonesms",
        params: { storeName: this.$store.storeName,lang: this.$i18n.locale }
        }) }}
      </div>
      <div v-else>
        <!--without firebase-->
        {{ this.$router.push({
        name: "loginPhone",
        params: { storeName: this.$store.storeName ,lang: this.$i18n.locale}
        }) }}
      </div>
    </div>
                                <div class="text-center">
                                </div>
                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>


            <vodal
          :show="forgetVodal"
          animation="flip"
          @hide="forgetVodal = false"
         
          style="margin-top: 20px"
        >
         

          <div class="container" >
            <h5 style="color: var(--primary-color)">{{ $t("Entertheemail") }} :</h5>

            <input
              class="input-field mt-5"
              type="email"
              name="passemail"
              v-model="passemail"
              style="width: 100%"
              placeholder="example@gmail.com"
              required
            />

            <button class="button text-center mt-5" @click="resetpass()">{{ $t("continue") }}</button>
            <p style="text-align: center">{{ errorMessageVodal }}</p>
            <button @click="forgetEmail()" class="forgetpass w-100 text-center">
              <p>{{ $t("forgetemail") }}</p>
            </button>
          </div>
        </vodal>
        <vodal
          :show="forgetEmailVodal"
          animation="slideLeft"
          @hide="forgetEmailVodal = false"
          style="margin-top: 20px"
        >
          <div class="container">
            <h5 style="color: var(--primary-color)">{{ $t("contactUs") }} :</h5>
            <div dir="ltr" class="text-center" style="margin-top:60px">
              <a
                target="_blank"
                :href="'https://api.whatsapp.com/send?phone='+this.$store.state.phone+'&text=Hello!'"
              >
                <h5>
                  <i class="bi bi-telephone-fill"></i> {{ this.$store.state.phone }}
                </h5>
                <!--edittttttttttttt tow phone-->
              </a>
              <a target="_blank" :href="'mailto:'+  this.$store.state.email">
                <!--edittttttttttttt-->
                <h5 style="margin-top:20px">
                  <i class="bi bi-envelope-fill"></i> {{ this.$store.state.email }}
                </h5>
              </a>
            </div>
           
            <p style="text-align: center">{{ errorMessageVodal }}</p>
          </div>
        </vodal>
        <vodal
          :show="codeVodal"
          animation="slideLeft"
          @hide="codeVodal = false"
         
          style="margin-top: 20px"
        >
          <div class="container" >
            <h5
              style="
              color: var(--primary-color);
             text-align:center;
            "
            >{{ $t("EntertheCode") }} :</h5>
            <div dir="ltr" class="mt-5">
              <CodeInput
                :loading="false"
                required
                class="input"
                @change="onChange"
                @complete="onComplete"
              />
              <p
                style="margin-top: 10px; margin-bottom: 0; text-align: center;"
                v-if="$i18n.locale == 'en'"
              >{{ countDown }} second</p>
              <p
                style="margin-top: 10px; margin-bottom: 0; text-align: center;"
                v-else
              >{{ countDown }} ثانية</p>
            </div>
            <button
              v-if="countDown == 0"
              class="ResendCode"
              @click="ResendCode()"
            >{{ $t("ResendCode") }}</button>
            <button
              class="button text-center mt-5"
              @click="checkCode()"
              :disabled="countDown == 0"
            >{{ $t("continue") }}</button>
            <p style="text-align: center; margin-top: 10px">{{ errorMessageVodal }}</p>
            <div v-if="loading" class="empty text-center center-text">
                 
                 <img src="/pet_shop/assets/images/ajax-loader.gif"/>
                           <br>

                       </div>
          </div>
        </vodal>
        <vodal
          :show="newPassVodal"
          animation="slideLeft"
          @hide="newPassVodal = false"
          :width="500"
          :height="350"
          style="margin-top: 20px"
        >
          <div class="container" >
            <h5
              style="
              color: var(--primary-color);
              margin-left: 20px;
              margin-right: 20px;
            "
            >{{ $t("EnterNewPass") }} :</h5>
            <div dir="ltr" class="mt-5">
              <input
                id="newpassword"
                class="input-field"
                type="password"
                name="name"
                v-model="Newpassword"
                :placeholder="$t('EnterPass')"
                required
                minlength="8"
              />
            </div>
            <button class="button text-center mt-5" @click="SaveChanges()">{{ $t("SaveChanges") }}</button>
            <p style="text-align: center; margin-top: 10px">{{ errorMessageVodal }}</p>
            <div v-if="loading" class="empty text-center center-text">
                 
                 <img src="/pet_shop/assets/images/ajax-loader.gif"/>
                           <br>

                       </div>
          </div>
        </vodal>
        </section><!--end section -->

</template>
<script>
import Form from "vform";
import { AlertErrors, AlertSuccess } from "vform/src/components/bootstrap5";
import CodeInput from "vue-verification-code-input";

import Vodal from "vodal";

import "vodal/common.css";
import "vodal/slide-down.css";
import "vodal/flip.css";
import "vodal/slide-left.css";

import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  components: {
    AlertErrors,
    AlertSuccess,
    Vodal,
    CodeInput,
    VuePhoneNumberInput
  },
  data: function() {
    return {
      loading:false,
      forgetVodal: false,
      forgetEmailVodal: false,
      codeVodal: false,
      newPassVodal: false,
      DBitems: null,
      errorMessageVodal: "",
      errorMessage: "",
      passemail: "",
      Newpassword: null,
      checkcomplete: false,
      validateMessage: "",
      finalcode: null,
      form: new Form({
        email: null,
        password: null,
        fsm_token: this.$store.state.fsm_token
      }),
      loading:false,
      isValid: false,
      partphone: null,
      phone: null,
      emailTrue: false,

      countDown: 90
    };
  },
  created() {
    this.$store.state.topbar='topbar_dark';
    this.$store.state.navbar='navbar_dark navbar_top';

    this.loading=true;
    if (this.$localStorage.get(this.$store.storeName) != null) {
      this.$router.push({
        name: "home",
        params: { storeName: this.$store.storeName }
      });
    }
    this.loading=false;
    if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
  },
  mounted() {
    try {
      if (this.$i18n.locale == "ar") {
        document
          .getElementsByClassName("input-tel__input")[0]
          .setAttribute("placeholder", "رقم الهاتف");
        document.getElementsByClassName(
          "country-selector__label"
        )[0].innerHTML = "رمز الدولة";
        document.getElementsByClassName("input-tel__label")[0].innerHTML =
          "* رقم الهاتف"; 
      }
    } catch (e) {}
  },
  methods: {

 
    async submitInfo() {
      this.loading=true;
      const response = await this.form
        .post(
          this.$store.state.base_api_url + this.$store.storeName + "/login",
          {
            email: this.form.email,
            password: this.form.password,
            fsm_token: this.form.fsm_token,
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.loading=false;
          if (response.data.code == "1") {
            ////////////// , initializes a session with a 'session-id'.///////////////////
            //this.$localStorage.start();
            this.$localStorage.set(
              this.$store.storeName,
              response.data.data.token
            );
            //this.$localStorage.set("userToken", response.data.data.token);
            console.log(
              this.$localStorage.get(this.$store.storeName) +
                " " +
                this.$store.storeName
            );

            
            window.location.href =
              "/";
          } else if (response.data.code == "-1") {
            this.errorMessage = response.data.message;
            setTimeout(() => {
              this.errorMessage = "";
            }, 3000);
          }
          if (response.data.error) {
            this.errorMessage = "response.data.error";
            setTimeout(() => {
              this.errorMessage = "";
            }, 3000);
          }
        });
    },
    forgetEmail() {
      this.forgetVodal = false;
      this.forgetEmailVodal = true;
      this.passemail = null;
    },
    sendPhone1(event) {
      this.isValid = event.isValid;
      if (this.isValid == true) {
        this.phone = event.e164; //+ (' +' +event.countryCallingCode));
        //console.log(this.form.phone)
      }
      //console.log(event.isValid);
      //console.log(event);
    },
    resetpass() {
      this.loading=true;
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/forget_password",
          {
            ///update_password
            email: this.passemail
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.loading=false;
          if (response.data.code == "1") {
            this.forgetVodal = false;
            this.forgetEmailVodal = false;
            this.codeVodal = true;
            this.emailTrue = true;
            this.countDown = 90;
            this.countDownTimer();
            // console.log(response.data.message);
          } else if (response.data.code == "-1") {
            this.errorMessageVodal = response.data.message;
            setTimeout(() => {
              this.errorMessageVodal = "";
            }, 3000);
          }
          if (response.data.error) {
            this.errorMessageVodal = response.data.message;
            setTimeout(() => {
              this.errorMessageVodal = "";
            }, 3000);
          }
        });
    },
    resetpass1() {
      this.loading=true;
      if (this.isValid == true) {
        this.axios
          .post(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/forget_password",
            {
              ///update_password
              phone: this.phone
            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale
              }
            }
          )
          .then(response => {
            this.loading=false;
            if (response.data.code == "1") {
              this.forgetVodal = false;
              this.forgetEmailVodal = false;
              this.codeVodal = true;
              this.emailTrue = false;
              this.countDown = 90;
              this.countDownTimer();
              // console.log(response.data.message);
            } else if (response.data.code == "-1") {
              this.errorMessageVodal = response.data.message;
              setTimeout(() => {
                this.errorMessageVodal = "";
              }, 3000);
            }
            if (response.data.error) {
              this.errorMessageVodal = response.data.message;
              setTimeout(() => {
                this.errorMessageVodal = "";
              }, 3000);
            }
          });
      } else {
        if (this.$i18n.locale == "en") {
          this.errorMessageVodal = "your number isn't valid";
          setTimeout(() => {
            this.errorMessageVodal = "";
          }, 3000);
        } else {
          this.errorMessageVodal = "هذا الرقم غير صالح";
          setTimeout(() => {
            this.errorMessageVodal = "";
          }, 3000);
        }
      }
    },
    checkCode() {
      this.loading=true;
      if (this.checkcomplete == true) {
        this.axios
          .post(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/verivey_reset_password",
            {
              ///update_password
              code: this.finalcode,
              email: this.passemail,
              phone: this.phone
            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale
              }
            }
          )
          .then(response => {
            this.loading=false;
            if (response.data.code == "1") {
              this.forgetVodal = false;
              this.forgetEmailVodal = false;
              this.codeVodal = false;
              this.newPassVodal = true;
              // console.log(response.data.message);
            } else if (response.data.code == "-1") {
              this.errorMessageVodal = response.data.message;
              setTimeout(() => {
                this.errorMessageVodal = "";
              }, 3000);
            }
            if (response.data.error) {
              this.errorMessageVodal = response.data.message;
              setTimeout(() => {
                this.errorMessageVodal = "";
              }, 3000);
            }
          });
      } else {
        this.loading=false;
        if (this.$i18n.locale == "en") {
          this.errorMessageVodal = "your code isn't complete";
          setTimeout(() => {
            this.errorMessageVodal = "";
          }, 3000);
        } else {
          this.errorMessageVodal = "الرمز المدخل غير مكتمل";
          setTimeout(() => {
            this.errorMessageVodal = "";
          }, 3000);
        }
      }
    },
    SaveChanges() {
      this.loading=true;
      if (this.Newpassword != null) {
        this.axios
          .post(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/update_password",
            {
              ///update_password
              password: this.Newpassword,
              email: this.passemail,
              phone: this.phone,
              fsm_token: this.form.fsm_token
            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale
              }
            }
          )
          .then(response => {
            this.loading=false;
            if (response.data.code == "1") {
              this.forgetVodal = false;
              this.forgetEmailVodal = false;
              this.codeVodal = false;
              this.newPassVodal = false;
              // console.log(response.data.message);

              ////////////// , initializes a session with a 'session-id'.///////////////////
              //this.$localStorage.start();
              this.$localStorage.set(
                this.$store.storeName,
                response.data.data.token
              );
              //this.$localStorage.set("userToken", response.data.data.token);
              console.log(
                this.$localStorage.get(this.$store.storeName) +
                  "  " +
                  this.$store.storeName
              );

              //console.log("logIn user token : " + this.$session.get("userToken"));
              /*this.$router.push({
                name: "profile",
                params: { storeName: this.$store.storeName },
              });
              window.location.reload();*/
              window.location.href =
                "/pet_shop/profile";
              //window.location.href= "https://192.168.1.16:8000/"+this.$store.storeName +"/profile"
            } else if (response.data.code == "-1") {
              this.errorMessageVodal = response.data.message;
              setTimeout(() => {
                this.errorMessageVodal = "";
              }, 3000);
            }
            if (response.data.error) {
              this.errorMessageVodal = response.data.message;
              setTimeout(() => {
                this.errorMessageVodal = "";
              }, 3000);
            }
          });
      } else {
        this.loading=false;
        this.errorMessageVodal = "error";
        setTimeout(() => {
          this.errorMessageVodal = "";
        }, 3000);
      }
    },
    ResendCode() {
      this.loading=true;
      let val = {};
      if (this.emailTrue == true) {
        val = { email: this.passemail };
      } else {
        val = { phone: this.phone };
      }
      console.log("val" + val);
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/forget_password",
          val,
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.loading=false;
          if (response.data.code == "-1") {
            this.errorMessageVodal = response.data.message;
            setTimeout(() => {
              this.errorMessageVodal = "";
            }, 3000);
          } else if (response.data.code == "1") {
            this.countDown = 90;
            this.countDownTimer();
            this.errorMessageVodal = response.data.message;
            setTimeout(() => {
              this.errorMessageVodal = "";
            }, 3000);
          }
        });
    },
    onChange(v) {
      //console.log("onChange ", this.phone);
      this.checkcomplete = false;
    },
    onComplete(v) {
      //console.log("onComplete ", v);
      this.finalcode = v;
      this.checkcomplete = true;
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          if (this.countDown > 0) {
            this.countDown -= 1;
            this.countDownTimer();
          }
        }, 1000);
      }
    }
  }
};
</script>
