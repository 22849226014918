<template>
  <section id="Second" v-if="this.$localStorage.get(this.$store.storeName) != null">
    <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-4 mb-4">
          <router-link 
          :to="{
              name: 'profile',
              params: { storeName: this.$store.storeName,lang: $i18n.locale },
            }"
           class="profile_btn">
            {{
            $t("profile")
            }}
          </router-link>
          <router-link
          :to="{
              name: 'myAddresses',
              params: { storeName: this.$store.storeName,lang: $i18n.locale },
            }">
            {{
            $t("myAddresses")
            }}
          </router-link>
          <router-link 
          :to="{
              name: 'myOrders',
              params: { storeName: this.$store.storeName,lang: $i18n.locale },
            }"
          class="profile_btn">
            {{
            $t("myOrders")
            }}
          </router-link>
        </div>
        <div class="col-lg-9 col-md-8 mb-4">
          <div class="d-flex flex-row justify-content-around mb-4">
            <button
              style="border: 0;"
              :class="'button ' + (activeTab ? '' : 'active')"
              @click="
                activeTab ? (activeTab = !activeTab) : (activeTab = activeTab)
              "
            >
              <p style="margin: 0; margin-right: 5px">{{$t('CurrentOrders')}}</p>
            </button>
            <button
              style="border: 0;"
              :class="'button ' + (activeTab ? 'active' : '')"
              @click="
                !activeTab ? (activeTab = !activeTab) : (activeTab = activeTab)
              "
            >
              <p style="margin: 0; margin-right: 5px">{{$t('PreviousOrders')}}</p>
            </button>
          </div>
          <div
            :style="activeTab ? 'display:none' : 'display: block'"
            id="tab1"
            class="container"
            data-aos="fade-up"
            v-if="!loading"
          >
            <div class="row">
              <div class="col-lg-6 mb-4" v-for="(item, index) in DBitems" :key="index">
                <div
                  class="card"
                  @click="$router.push({name: 'invoice',params: { storeName: $store.storeName ,id:item.id }})"
                >
                  <div class="container mt-3 mb-3">
                    <div class="d-flex flex-row justify-content-between align-items-center">
                      <div>
                        <h4 class="m-0">#{{item.invoice_id}}</h4>
                      </div>
                      <div>
                        <h4
                          v-if=" $i18n.locale == 'ar'"
                          class="m-0"
                        >{{item.total_price}} {{$store.state.unitAR}}</h4>
                        <h4 v-else class="m-0">{{item.total_price}} {{$store.state.unit}}</h4>
                      </div>
                    </div>
                    <span>{{item.date}}</span>
                    <!--<ul>
                      <li>
                        5 تفاح
                      </li>
                      <li>
                        6 عنب
                      </li>
                    </ul>-->
                    <hr />
                    <div class="row">
                      <div class="col-sm-7">
                        <h4>{{item.state}}</h4>
                      </div>
                      <div class="col-sm-5" style="text-align: center" v-if="item.can_cancel">
                        <button class="state">
                          <h4
                            style="width: 100%; color: #fff; font-size: 0.9rem;"
                          >{{$t('cancelOrder')}}</h4>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-center mt-5" v-if="this.count>1">
              <b-pagination
                v-model="page"
                :total-rows="count"
                :per-page="pageSize"
                :prev-text="$t('prev')"
                :next-text="$t('next')"
                pills
                @change="handlePageChange"
              ></b-pagination>
            </div>
          </div>
          <div
            id="tab2"
            :style="activeTab ? 'display:block' : 'display:none'"
            class="container w3-container w3-display-container Tab"
            style="display: none"
            data-aos="fade-up"
            v-if="!loading1"
          >
            <div class="row">
              <div class="col-lg-6 mb-4" v-for="(item, index) in DBitems1" :key="index">
                <div
                  class="card"
                  @click="$router.push({name: 'invoice',params: { storeName: $store.storeName ,id:item.id }})"
                >
                  <div class="container mt-3 mb-3">
                    <div class="d-flex flex-row justify-content-between align-items-center">
                      <div>
                        <h4 class="m-0">#{{item.invoice_id}}</h4>
                      </div>
                      <div>
                        <h4
                          v-if=" $i18n.locale == 'ar'"
                          class="m-0"
                        >{{item.total_price}} {{$store.state.unitAR}}</h4>
                        <h4 v-else class="m-0">{{item.total_price}} {{$store.state.unit}}</h4>
                      </div>
                    </div>
                    <span>{{item.date}}</span>
                    <hr />
                    <h4 style="text-align:center">{{item.state}}</h4>
                    <!--<hr />
                    <div class="row">
                      <div class="col-sm-7">
                        <h4>{{item.state}} </h4>
                      </div>
                      <div class="col-sm-5" style="text-align: center" v-if="item.can_cancel">
                        <div class="state">
                          <h4
                            style="width: 100%; color: #fff; font-size: 0.9rem"
                          >
                            إلغاء الطلب
                          </h4>
                        </div>
                      </div>
                    </div>-->
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-row justify-content-center mt-5" v-if="this.count1>1">
              <b-pagination
                v-model="page1"
                :total-rows="count1"
                :per-page="pageSize1"
                :prev-text="$t('prev')"
                :next-text="$t('next')"
                pills
                @change="handlePageChange1"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data: function() {
    return {
      DBitems: null,
      DBitems1: null,

      loading: false,
      loading1: false,

      activeTab: false,
      page: 1,
      count: 0,
      pageSize: 6,

      page1: 1,
      count1: 0,
      pageSize1: 6

      // langChange:""
    };
  },
  created() {
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/orders/current",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.DBitems = response.data.data.orders;
        this.count = response.data.meta.total;
      })
      .then(() => (this.loading = false))
      .catch(error => {
        if (error.response.status == "401") {
          this.$localStorage.remove(this.$store.storeName);
                    window.location.href="/";

        }
      });

    ///prev
    this.loading1 = true;
    this.axios
      .get(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/orders/previous",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.DBitems1 = response.data.data.orders;
        this.count1 = response.data.meta.total;
        this.pageSize=response.data.meta.per_page;

      })
      .then(() => (this.loading1 = false))
      .catch(error => {
        if (error.response.status == "401") {
          this.$localStorage.remove(this.$store.storeName);
                    window.location.href="/";

        }
      });

      if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
  },
  methods: {
    updatedata() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/orders/current",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data.orders;
          this.count = response.data.meta.total;
          this.pageSize=response.data.meta.per_page;

        })
        .then(() => (this.loading = false))
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
                      window.location.href="/";

          }
        });

      ///prev
      this.loading1 = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/orders/previous",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.DBitems1 = response.data.data.orders;
          this.count1 = response.data.meta.total;
        })
        .then(() => (this.loading1 = false))
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
                      window.location.href="/";

          }
        });
      this.$store.state.langchange = false;
    },
    retrieveTutorials() {
      const params = this.page;

      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/orders/current?page=" +
            params,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data.orders;
          //this.count = response.data.meta.total;
        })
        .then(() => (this.loading = false))
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
                      window.location.href="/";

          }
        });
      /*.catch((e) => {
          console.log(params);
          console.log(e);
        });*/
    },
    handlePageChange(value) {
      this.page = value;
      this.retrieveTutorials();
    },
    retrieveTutorials1() {
      const params = this.page1;

      this.loading1 = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/orders/previous?page=" +
            params,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.DBitems1 = response.data.data.orders;
          //this.count = response.data.meta.total;
          this.pageSize=response.data.meta.per_page;

        })
        .then(() => (this.loading1 = false))
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
                      window.location.href="/";

          }
        });
      /*.catch((e) => {
          console.log(params);
          console.log(e);
        });*/
    },
    handlePageChange1(value) {
      this.page1 = value;
      this.retrieveTutorials1();
    }
  }
};
</script>

<style scoped>
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/

#Second {
  margin-top: 50px;
  min-height: 600px;
}

a {
  display: block;
  background-color: #f8f8f8;
  padding: 13px 0 17px 0;
  width: 80%;
  border-radius: 15px;
  color: #222;
  font-family: "Araboto-Bold";
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
  /* border: 2px solid var(--primary-color);*/
}
a:hover {
  color: var(--primary-color) !important;
}
.router-link-exact-active,
.router-link-exact-active:focus {
  background-color: #fff;
  border: 2px solid var(--primary-color);
}

span {
  color: #777777;
  font-size: 0.7rem;
}

h4 {
  color: var(--secondary-color);
  font-size: 0.9rem;
  line-height: 1.6em;
}

h3 {
  color: #ffffff;
  font-size: 1.1rem;
  margin-top: 5px;
}

h2 {
  font-size: 1.6rem;
}

i {
  color: #ed472b;
}

.state {
  width: 100%;
  padding: 5px 0 3px 0;
  background-color: var(--primary-color);
  border-radius: 25px;
  border: 0;
}
.state:hover {
  box-shadow: 0 0px 15px 0 var(--primary-color);
}

.button {
  color: #777e90;
  background-color: transparent;
  font-size: 1rem;
  padding: 1% 2%;
  text-decoration: underline 2px #c7c7c7;
  text-underline-offset: 10px;
}

.button:hover,
.active {
  color: var(--primary-color);
  text-decoration: underline 2px var(--primary-color);
}

.card {
  cursor: pointer;
  height: 100%;
  background-color: #fff;
  border: 0;
  border-radius: 15px;
  box-shadow: 0 0px 10px 0 rgba(22, 22, 26, 0.18);
}

.card:hover {
  box-shadow: 0 0px 25px 0 rgba(22, 22, 26, 0.18);
}

/*@media (min-width: 1400px){
.container {
    max-width: 1400px !important;
}

}*/

@media (max-width: 992px) {
  a {
    width: 100%;
  }
}
@media (max-width: 772px) {
  a {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }
}
@media (max-width: 575px) {
  .state {
    width: 150px;
    border-radius: 5px;
    /*margin-left: 20%;
    margin-right: 20%;*/
  }
}
</style>
